export const authConstant = {
  SIGNIN_REQUEST: "SIGNIN_REQUEST",
  SIGNIN_SUCCESS: "SIGNIN_SUCCESS",
  SIGNIN_FAILURE: "SIGNIN_FAILURE",

  GOOGLE_SIGNIN_REQUEST: "GOOGLE_SIGNIN_REQUEST",
  GOOGLE_SIGNIN_SUCCESS: "GOOGLE_SIGNIN_SUCCESS",
  GOOGLE_SIGNIN_FAILURE: "GOOGLE_SIGNIN_FAILURE",

  SIGNUP_REQUEST: "SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",

  SIGNOUT_REQUEST: "SIGNOUT_REQUEST",
  SIGNOUT_SUCCESS: "SIGNOUT_SUCCESS",
  SIGNOUT_FAILURE: "SIGNOUT_FAILURE",

  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

  UPDATE_SUCCESS: "UPDATE_SUCCESS",
  UPDATE_REQUEST: "UPDATE_REQUEST",
  UPDATE_FAILURE: "UPDATE_FAILURE",

  UPDATE_REGISTER_FLAG: "UPDATE_REGISTER_FLAG",
  UPDATE_FORGOT_PASS_FLAG: "UPDATE_FORGOT_PASS_FLAG",

  CLEAR_LOGIN_SIGNUP_ERROR: "CLEAR_LOGIN_SIGNUP_ERROR",
  CLEAR_LOGIN_SIGNUP_SUCCESS: "CLEAR_LOGIN_SIGNUP_SUCCESS",

  EMAIL_SUBSCRIPTION_REQUEST: "EMAIL_SUBSCRIPTION_REQUEST",
  EMAIL_SUBSCRIPTION_SUCCESS: "EMAIL_SUBSCRIPTION_SUCCESS",
  EMAIL_SUBSCRIPTION_FAILURE: "EMAIL_SUBSCRIPTION_FAILURE"
};
