
// import { authConstant } from "../actions/auth.action";
import { authConstant } from "../constants/auth";
const initState = { success: {}, error: {}, auth: false, submitted: false, regSubmitted: false, logoutFlag: true, resetPassFlag: false, forgotPasswordFlag: false, userDetails: {},emailSubmitted:false };

export const authReducer = (state = initState, action) => {
  switch (action.type) {
    case authConstant.SIGNIN_REQUEST:
      return { ...state, submitted: true };
      break;

    case authConstant.SIGNIN_SUCCESS:
      return {
        auth: true,
        userDetails: action.payload,
        submitted: false,
        logoutFlag: true,
        error: {},
        // success: {"type" : "signin", "message" : action.payload.message, "category" : authConstant.CLEAR_LOGIN_SIGNUP_SUCCESS }
      };
      break;

    case authConstant.SIGNIN_FAILURE:
      return {
        ...state,
        submitted: false,
        error: { "type": "signin", "message": action?.payload?.message, "category": authConstant.CLEAR_LOGIN_SIGNUP_ERROR }
      };
      break;

    case authConstant.GOOGLE_SIGNIN_REQUEST:
      return { ...state, submitted: true };
      break;

    case authConstant.GOOGLE_SIGNIN_SUCCESS:
      return {
        auth: true,
        userDetails: action.payload,
        submitted: false,
        logoutFlag: true,
        error: {},
        // success: {"type" : "signin", "message" : action.payload.message, "category" : authConstant.CLEAR_LOGIN_SIGNUP_SUCCESS }
      };
      break;

    case authConstant.GOOGLE_SIGNIN_FAILURE:
      return {
        ...state,
        submitted: false,
        // error: {"type" : "signin", "message" : action.payload.message, "category" : authConstant.CLEAR_LOGIN_SIGNUP_ERROR} 
      };
      break;

    case authConstant.SIGNOUT_REQUEST:
      return { ...state, logoutFlag: false };
      break;

    case authConstant.SIGNOUT_SUCCESS:
      return { ...initState, auth: false, logoutFlag: true };
      break;

    case authConstant.SIGNOUT_FAILURE:
      return { ...state, auth: true, logoutFlag: true };
      break;

    case authConstant.FORGOT_PASSWORD_REQUEST:
      return { ...state, submitted: true, forgotPasswordFlag: false };
      break;

    case authConstant.FORGOT_PASSWORD_SUCCESS:
      return { auth: true, userDetails: action.payload, forgotPasswordFlag: true, error: {} };
      break;

    case authConstant.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        submitted: false,
        forgotPasswordFlag: false,
        error: { "type": "forgotPassword", "message": action.payload.message, "category": authConstant.CLEAR_LOGIN_SIGNUP_ERROR }
      };
      break;

    case authConstant.RESET_PASSWORD_REQUEST:
      return { ...state, submitted: true };
      break;

    case authConstant.RESET_PASSWORD_SUCCESS:
      return { ...state, submitted: false, resetPassFlag: true, error: {} };
      break;

    case authConstant.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        submitted: false,
        resetPassFlag: false,
        error: { "type": "resetPassword", "message": action.payload.message, "category": authConstant.CLEAR_LOGIN_SIGNUP_ERROR }
      };
      break;

    case authConstant.SIGNUP_REQUEST:
      return { ...state, regSubmitted: true };
      break;

    case authConstant.SIGNUP_SUCCESS:
      return { auth: true, userDetails: action.payload, regSubmitted: false, error: {} };
      break;

    case authConstant.SIGNUP_FAILURE:
      return {
        ...state,
        regSubmitted: false,
        error: { "type": "signup", "message": action.payload.message, "category": authConstant.CLEAR_LOGIN_SIGNUP_ERROR }
      };
      break;

    case authConstant.UPDATE_REQUEST:
      return { ...state, submitted: true, logoutFlag: true };
      break;

    case authConstant.UPDATE_SUCCESS:
      return { auth: true, userDetails: action.payload, submitted: false, logoutFlag: true, error: {} };
      break;

    case authConstant.UPDATE_FAILURE:
      return {
        ...state,
        submitted: false,
        logoutFlag: true,
        error: { "type": "profile", "message": action.payload.message, "category": authConstant.CLEAR_LOGIN_SIGNUP_ERROR }
      };
      break;

    case authConstant.UPDATE_REGISTER_FLAG:
      const stateObj = initState;
      stateObj.userDetails.flag = false;
      stateObj.userDetails.email = action.payload;
      return stateObj;
      break;

    case authConstant.UPDATE_FORGOT_PASS_FLAG:
      const stateObject = initState;
      stateObject.forgotPasswordFlag = false;
      stateObject.userDetails.email = action.payload;
      return stateObject;
      break;

    case authConstant.CLEAR_LOGIN_SIGNUP_ERROR:
      return { ...state, error: {} };
      break;

    case authConstant.EMAIL_SUBSCRIPTION_REQUEST:
      return { ...state, emailSubmitted: true,userDetails:{} };
      break;

    case authConstant.EMAIL_SUBSCRIPTION_SUCCESS:
      return { ...state,auth: true, userDetails: action.payload, error: {} ,emailSubmitted: false};
      break;

    case authConstant.EMAIL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        emailSubmitted: false,
        userDetails:{} ,
        error: { "type": "emailSubscription", "message": action?.payload }
      };
      break;

    default:
      return state;
      break;
  }
};
