import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormInput } from '../../utility/FormInput';
import { auth, authConstant } from '../../actions/auth.action';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

// Email validation schema
const emailRegx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const schema = yup.object().shape({
  email: yup.string().email('Email must be a valid email').matches(emailRegx, 'Please enter a valid email'),
});

function Footer() {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const [focusedInput, setFocusedInput] = useState(null);

  const {
    register: registerMobile,
    handleSubmit: handleSubmitMobile,
    formState: { errors: errorsMobile },
    reset: reset1,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const {
    register: registerDesktop,
    handleSubmit: handleSubmitDesktop,
    formState: { errors: errorsDesktop },
    reset: reset2,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmitMobile = (data) => {
    dispatch({ type: authConstant.EMAIL_SUBSCRIPTION_REQUEST });
    dispatch(auth.emailSubscription(data));
  };
  
  const onSubmitDesktop = (data) => {
    dispatch({ type: authConstant.EMAIL_SUBSCRIPTION_REQUEST });
    dispatch(auth.emailSubscription(data));
  };
  
  // Effect to reset input field only when request is successful
  useEffect(() => {
    if (authState?.userDetails?.message === "Created Successfully") {
      reset1();
      reset2();
    }
  }, [authState?.userDetails?.message]);
  

  const handleInputFocus = (fieldName) => {
    setFocusedInput(fieldName);
  };

  const handleInputBlur = () => {
    setFocusedInput(null);
  };

  return (
    <div>
      <footer>
        <div className="container-fluid">
          <div className="row mx-4">
            <div className="col-12 col-md-3 news-lt-mob mo-aligncenter d-md-none">
              <div className="widget news-ltr">
                <h5 className="widgetheading">Stay in Touch</h5>
                <form className="subscribe subscribe-1" onSubmit={handleSubmitMobile(onSubmitMobile)}>
                  <div className="row">
                    <div className="input-append2 col-8">
                      <FormInput
                        type="text"
                        identity="email"
                        name="email"
                        autoComplete="off"
                        placeholder="Email"
                        register={registerMobile('email')}
                        error={errorsMobile?.email}
                        className={`form-control custom-input-floating ${errorsMobile?.email ? 'is-invalid' : ''}`}
                        onFocus={() => handleInputFocus('email')}
                        onBlur={handleInputBlur}
                      />
                    </div>
                    <div className="col-4">
                      {authState.emailSubmitted ? (
                        <button className="btn btn-theme subscribe-btn" disabled>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{' '}
                          Loading...
                        </button>
                      ) : (
                        <button className="btn btn-theme subscribe-btn subscribe-btn-mobile" type="submit">
                          SUBSCRIBE
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {/* About Section */}
            <div className="col-lg-3 col-md-3 col-12 eql abt-u">
              <div className="widget">
                <h5 className="widgetheading">About ShopThumb</h5>
                <ul className="link-list">
                  <li>
                    <Link to="/aboutUs">About Us</Link>
                  </li>
                </ul>
              </div>
            </div>

            {/* Customer Service Section */}
            <div className="col-lg-3 col-md-3 col-12 eql cus-ser">
              <div className="widget">
                <h5 className="widgetheading">CUSTOMER SERVICE</h5>
                <ul className="link-list">
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                  <li>
                    <a href="#">Terms of use</a>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>

            {/* Desktop email form positioned 3rd */}
            <div className="col-lg-4 col-md-4 d-none d-md-block">
              <div className="widget news-ltr">
                <h5 className="widgetheading">Stay in Touch</h5>
                <form className="subscribe" onSubmit={handleSubmitDesktop(onSubmitDesktop)}>
                  <div className="row">
                    <div className="input-append2 col-md-6">
                      <FormInput
                        type="text"
                        identity="email"
                        name="email"
                        autoComplete="off"
                        placeholder="Email"
                        register={registerDesktop('email')}
                        error={errorsDesktop?.email}
                        className={`form-control custom-input-floating ${errorsDesktop?.email ? 'is-invalid' : ''}`}
                        onFocus={() => handleInputFocus('email')}
                        onBlur={handleInputBlur}
                      />
                    </div>
                    <div className="col-md-4">
                      {authState.emailSubmitted ? (
                        <button className="btn btn-theme subscribe-btn" disabled>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{' '}
                          Loading...
                        </button>
                      ) : (
                        <button className="btn btn-theme subscribe-btn " type="submit">
                          SUBSCRIBE
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {/* Connect Section */}
            <div className="col-lg-2 col-md-2 col-12">
              <div className="conct_app">
                <h5 className="widgetheading">Connect</h5>
                <ul className="link-list">
                  <li>
                    <a href="https://facebook.com/shopthumb" target="_blank" rel="noopener noreferrer">
                      <i className="fa fa-facebook-official" aria-hidden="true"></i>
                      <span>Facebook</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/shopthumb" target="_blank" rel="noopener noreferrer">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                      <span>Twitter</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/shopthumb_" target="_blank" rel="noopener noreferrer">
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                      <span>Instagram</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div id="sub-footer">
          <div className="container-fluid">
            <p className="copyright">
              <span>&copy; 2019 ShopThumb</span>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
