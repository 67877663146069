import axios from "axios";
import { toast } from "react-toastify";
import { config } from "../../constants/config";

function returnAxiosInstance() {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: config.TIMEOUT,
  });
}

export function get(url, headers = {}) {
  const axios = returnAxiosInstance();
  return axios.get(url, {
    headers,
  });
}

export async function post(url, requestData, headers = {}) {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}${url}`, requestData, {
      headers,
    });
    
    return response;
  } catch (error) {
    return error;
    
  }
} 
