
import { contactConstant } from "../actions/contact.action";
const initState = { submitted: false, contactFlag: false, isSuccess: false };

export const contactReducer = (state = initState, action) => {
    switch (action.type) {
        case contactConstant.CONTACT_REQUEST:
            return { submitted: true, contactFlag: false ,isSuccess: false};

        case contactConstant.CONTACT_SUCCESS:
            return { submitted: false, isSuccess: true };

        case contactConstant.CONTACT_FAILURE:
            return { ...state ,submitted: false,isSuccess: false};
        
        case contactConstant.UPDATE_CONTACT_FLAG:
            return { ...state };

        case contactConstant.RESET_CONTACT_STATE:
            return { ...state, isSuccess: false };

        default:
            return state;
    }
};
