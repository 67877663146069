import React from 'react'
import { BrowserRouter as Router, Routes, Route, Switch } from 'react-router-dom'
import Layout from '../components/Layout/Layout'
import Home from './Home/Home'
import About from './About/About'
import Register from './Register/Register'
import Login from './Login/Login'
import EmailVerification from './EmailVerification/EmailVerification';
import EmailVerificationMessage from './EmailVerificationMessage/EmailVerificationMessage';
import Profile from './Accounts/Profile/Profile'
import history from '../helpers/history'
import ForgotPassword from './Login/ForgotPassword'
import ResetPassword from './Login/ResetPassword'
import ProducstList from './Products/ProducstList'
import SearchProductList from './Products/SearchProductList'
import HomepageProductList from './Products/HomepageProductList'
import ProductListByCategory from './Products/ProductListByCategory'
// import WishMOdal from './productModal/WishModal'
import QuickViewModal from './productModal/QuickViewModal'
import ContactUs from './ContactUs/ContactUs'
import ContactUsMessage from './ContactUsMessage/ContactUsMessage';
import ForgotPasswordMessage from './ForgotPasswordMessage/ForgotPasswordMessage';
import MyFav from './MyFav/myFav';
import Privacy from '../components/Footer/Privacy'
import AboutUs from './AboutUs/AboutUs'
import Brand from './Accounts/Brand/Brand'
import ProfileEdit from './Accounts/Profile/ProfileEdit'

function Webpages() {
  
  return (
    <Router history={history}>
      <Layout>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/login/forgot" element={<ForgotPassword />} />
          <Route exact path="/signup" element={<Register />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/profile-edit" element={<ProfileEdit />} />
          <Route exact path="/emailVerificaton/:id" element={<EmailVerification page='email'/>} />
          <Route exact path="/email-subscription/verify/:id" element={<EmailVerification page='subscription-email' />} />
          <Route exact path="/resetpassword/:id" element={<ResetPassword />} />
          {/* <Route exact path="/modal" element={<WishMOdal />} /> */}
          <Route exact path="/qmodal" element={<QuickViewModal />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/register-success" element={<EmailVerificationMessage />} />
          <Route exact path="/contact-us-success" element={<ContactUsMessage />} />
          <Route exact path="/reset-password/success-message" element={<ForgotPasswordMessage />} />
          <Route exact path="/my-favourite" element={<MyFav />} />
          {/* Category Filter on PLP  */}
          {/* <Route exact path="/products" element={<ProducstList location={window.location.href}/>} /> */}
          <Route path="/browse/:parentCateg/:subCateg/:childCateg" element={<ProducstList flag='categoryFilter' />} />
          <Route path="/browse/:parentCateg/:subCateg" element={<ProducstList flag='categoryFilter' />} />
          <Route path="/search" element={<SearchProductList />} />
          <Route path="/shop/:homepageKey" element={<HomepageProductList />} />
          <Route path="/shop/category/:categoryName" element={<HomepageProductList />} />
          <Route exact path="/privacy-policy" element={<Privacy/>}/>
          <Route exact path="/aboutUs" element={<AboutUs/>}/>
          <Route exact path="/brand" element={<Brand/>}/>
          
        </Routes>
      </Layout>
    </Router>
  )
}

export default Webpages
