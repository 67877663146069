import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import './style.css'
import $ from 'jquery'

import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { auth, authConstant } from '../../actions/auth.action';
import { categoryAction, categoryConstant } from '../../actions/category.action';
import { homeAction, homeConstant } from '../../actions/home.action';
import { productAction, productConstant } from "../../actions/productList.action";
import history from "../../helpers/history";
import ReactGA from 'react-ga';
import SideMenu from './SideMenu';

function Header() {
  const {pathname} = useLocation()
  let [navbar, setNavbar] = useState(false);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const allCategories = useSelector((state) => state.category?.categories);
  let searchData = useSelector((state) => state.category?.searchResult);
  const searchKeyword = useSelector((state) => state.category?.searchKeywordUrl);
  const [searchKey, setSearchKey] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [activeItem, setActiveItem] = useState(location.pathname);

  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        // document.querySelectorAll('.openDropwown').forEach(box => {
        //   box.classList.remove('show');
        // });
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);

  useEffect(() => {
    setSearchResult(searchData);
  }, [searchData])

  useEffect(() => {
    dispatch(categoryAction.getCategories());
  }, [])

  useLayoutEffect(() => {
    dispatch(categoryAction.getCategories());
  },[pathname])


  const logOut = () => {
    dispatch({ type: authConstant.SIGNOUT_REQUEST });
    dispatch(auth.signOut({ Token: authState.userDetails.token }))
  }


  const handleCategoryFilter = (e, url) => {
    dispatch({type: 'SET_CATEGORY_CLICKED_VALUE', payload:true})
    dispatch({ type: categoryConstant.SET_CATEGORY_URL_VALUE, payload: url });
  }

  // Handle search suggesstion functionality here...  
  const handleSearchSugg = (e) => {
    e.preventDefault();
    const value = e.target.value
    const button = document.getElementById('crossHeaderButton')

    if (value != '') {
      button.classList.remove('d-none')
    } else {
      button.classList.add('d-none')
    }

    if (e.target.value.length > 2) {
      let keyword = e.target.value.trimEnd().replace(/\s+/g, '+');
      setSearchKey(keyword);
      setSearchResult(searchData);
    } else {
      setSearchResult([]);
      setSearchKey('');
    }
  }

  // Handle search functionality here...
  const handleSearch = (e) => {
    e.preventDefault();
    if (searchKey.length > 2) {
      let url = `/search?keyword=${searchKey}`;
      dispatch({ type: categoryConstant.SET_SEARCH_KEYWORD_VALUE, payload: searchKey });
      dispatch({ type: homeConstant.SET_HOMEPAGE_KEY, payload: '' });
      window.location.href = url;
    } else {
      e.target.focus();
    }
  }

  const handleClearSearch = (e) => {
    e.preventDefault();
    setSearchKey('')
    document.getElementById('crossHeaderButton').classList.add("d-none")
    document.getElementsByClassName('search-box')[0].value = '';
    document.getElementsByClassName('search-box')[0].defaultValue = '';
    document.getElementsByClassName('search-box')[0].focus();

  }

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleDropdown = (e, indexValue) => {
    setHoveredIndex(indexValue);

    document.querySelectorAll('.dropdown').forEach((filter) => filter.classList.remove('show'));
  }




  document.addEventListener("DOMContentLoaded", () => {
    const sidebar = document.getElementById("sidebar");
    const menuExpand = document.getElementById("mob-menu-expand");
    const closeSidebar = document.getElementById("close-sidebar");
    const menuItems = document.querySelectorAll(".menu > li > a");

    // Open Sidebar
    menuExpand.addEventListener("click", (e) => {
      e.preventDefault();
      sidebar.classList.add("open");
    });

    // Close Sidebar
    closeSidebar.addEventListener("click", () => {
      sidebar.classList.remove("open");
    });

    // Drilldown Menu Functionality
    menuItems.forEach((item) => {
      item.addEventListener("click", (e) => {
        const submenu = item.nextElementSibling;
        if (submenu && submenu.classList.contains("submenu")) {
          e.preventDefault();
          submenu.classList.toggle("active");
        }
      });
    });
  });

  document.addEventListener("DOMContentLoaded", () => {
    const drilldownLinks = document.querySelectorAll("#drilldown-menu > li > a");

    // Drilldown Menu Toggle
    drilldownLinks.forEach((link) => {
      link.addEventListener("click", (e) => {
        const submenu = link.nextElementSibling;
        if (submenu && submenu.classList.contains("submenu")) {
          e.preventDefault(); // Prevent default link behavior
          submenu.classList.toggle("active"); // Toggle visibility
        }
      });
    });
  });

  document.addEventListener("DOMContentLoaded", () => {
    const sidebar = document.getElementById("sidebar");
    const mobMenuExpand = document.getElementById("mob-menu-expand");
    const closeBtn = document.getElementById("close-sidebar"); // Close button to close the sidebar

    // Open sidebar when menu trigger is clicked
    mobMenuExpand.addEventListener("click", (e) => {
      e.preventDefault(); // Prevent default link behavior
      sidebar.classList.add("active"); // Add 'active' class to show the sidebar
    });

    // Close the sidebar when the close button is clicked
    closeBtn.addEventListener("click", () => {
      sidebar.classList.remove("active"); // Remove 'active' class to hide the sidebar
    });
  });

  document.addEventListener("DOMContentLoaded", function () {
    // Sidebar toggle buttons
    const sidebar = document.getElementById("sidebar");
    const openSidebarBtn = document.getElementById("open-sidebar");
    const closeSidebarBtn = document.getElementById("close-sidebar");

    // openSidebarBtn.addEventListener("click", () => {
    //   sidebar.classList.add("show");
    // });

    // closeSidebarBtn.addEventListener("click", () => {
    //   sidebar.classList.remove("show");
    // });

    // Drill-down menu functionality
    const menuItems = document.querySelectorAll("#drilldown-menu li > a");

    menuItems.forEach((menuItem) => {
      menuItem.addEventListener("click", (e) => {
        const submenu = menuItem.nextElementSibling;

        if (submenu && submenu.classList.contains("submenu")) {
          e.preventDefault();

          // Toggle visibility of the submenu
          const isVisible = submenu.style.display === "block";
          submenu.style.display = isVisible ? "none" : "block";
        }
      });
    });
  });

  useEffect(() => {
    if (window.visualViewport.width <= 576) {
      let link = document.createElement('link');
      link.rel = "stylesheet";
      link.href = "/drilldownCss/foundation.min.css";
      let head = document.head;
      head.appendChild(link);
    }

  }, [])


  return (
    <>
      <div id="wrapper">
        <header>
          {/* top header  */}
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="hdr-top">
                  <a href="#" id="mob-menu-expand" class="menu-trigger">
                    <img src="/images/h-menu.png" alt="menu" title="menu" />
                  </a>

                  <div className="logo text-center">
                    <Link to='/'><h1>ShopThumb</h1></Link>
                  </div>
                  <div className="log-regi-cur">
                    <div className="login-register d-flex">
                      {/* Desktop header login link */}

                      <span className="d-none d-md-block ">
                        {authState.userDetails.id ?
                          <span className="log-text">
                            <Link to='/profile-edit'>Welcome, {authState.userDetails.first_name}</Link>
                            <Link onClick={() => logOut()} to='/' style={{ pointerEvents: authState.logoutFlag === true ? 'block' : 'none' }}>Logout</Link>
                          </span>
                          :
                          <span className="log-text login-text-desktop" >
                            <Link to='/login' style={{ textDecoration: 'none', marginLeft: '-100px' }}>Login / Register</Link>
                          </span>
                        }
                        {' '}
                      </span>

                      {/* Mobile Profile drop down  */}
                      <span className="ac-dd d-md-none">
                        <div className="dropdown">
                          <a
                            className="dropdown-toggle"
                            href="#"
                            role="button"
                            id="myAccount"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="fa fa-user" aria-hidden="true" style={{
                              fontSize: '28px', marginLeft: '10px'
                            }}>
                            </i>
                          </a>
                          <div
                            className={`dropdown-menu ${'show' ? 'me-6' : ''}`}
                            style={{ marginLeft: 'show' ? '-45px' : '0', marginTop: '-10px' }}
                            aria-labelledby="myAccount"
                          >
                            {
                              authState.userDetails.id ? null : <Link to='/login' className={activeItem === '/profile-edit' ? 'active-tem' : ''} onClick={() => setActiveItem('/profile-edit')} style={{ color: 'active-tem' ? 'white' : 'black', backgroundColor: 'active-tem' ? 'black' : 'white' }}>Login</Link>
                            }

                           <Link className="dropdown-item d-none d-md-block" to="#">
                              Register
                            </Link>
                            {authState?.auth &&
                              <Link className="dropdown-item" to="/profile-edit">
                                  My Account
                              </Link>
                            }
                           <Link className="dropdown-item d-md-none" to='/brand'>
                              My Brands
                            </Link>
                           <Link className="dropdown-item d-none d-md-block" to='/my-favourite'>
                              My Favorites
                            </Link>
                            {authState?.auth &&
                           <Link className="dropdown-item" to="javascript:void(0)" onClick={logOut} style={{ pointerEvents:authState.logoutFlag === true ? 'block' : 'none', display:authState.logoutFlag === true ? 'block':'none' }}>
                              Logout
                            </Link>}
                          </div>
                        </div>
                      </span>

                      {authState.auth &&
                        <span className="fav-section">
                          <Link to='/my-favourite'><i className="fa fa-heart-o" aria-hidden="true" style={{ marginRight: '-10px', fontSize: '16px', marginTop:'7px' }}></i></Link>
                        </span>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* bottom header  */}
          <div className="top" data-spy="affix" data-offset-top="90">
            <nav className="navbar navbar-expand-lg navbar-inverse p-0">
              <div className=" container-fluid">
                <div className="custom-menu">
                  <div className=" navbar-collapse">
                    <ul className="navbar-nav mr-5">
                      <li>
                        {/* <Link to='/products'>Product</Link> */}
                      </li>

                      {allCategories?.data?.length > 0 && allCategories.data.map((allCateg, index) => {
                        const isHovered = index === hoveredIndex;

                        return (
                          <li className={`dropdown  ${isHovered ? 'openDropwown show' : ''}`} key={index} onMouseEnter={(e) => handleDropdown(e, index)} onMouseLeave={() => setHoveredIndex(null)}>

                            <a data-toggle="dropdown" href="javascript:void(0)">
                              {allCateg.name}
                            </a>
                            {isHovered && (
                              <ul className={`dropdown-menu m-0 ${isHovered ? 'openDropwown show' : ''}`} data-hover="dropdown" style={{ zIndex: 999999 }}>

                                {allCateg.subCateg.length > 0 && allCateg.subCateg.map((subCateg, index) => {
                                  if (subCateg.childSubCateg.length > 0) {
                                    return (
                                      <li key={index}>
                                        <a>
                                          <h3>{subCateg.name}</h3>
                                        </a>
                                        <ul>
                                          {subCateg.childSubCateg.length > 0 && subCateg.childSubCateg.map((childSubCateg, index) => {
                                            return (
                                              <li key={index}>
                                                <Link to={`/browse/${childSubCateg.slug}`} onClick={(e) => handleCategoryFilter(e, '/browse/' + allCateg.slug + '/' + subCateg.slug + '/' + childSubCateg.slug)}>{childSubCateg.name}</Link>
                                              </li>
                                            )
                                          })}
                                        </ul>
                                      </li>
                                    )
                                  } else {
                                    return (
                                      <ul>
                                        <li key={index}>
                                          {/* <a href="#">{childSubCateg.name}</a> */}
                                          <Link to={`/browse/${allCateg.slug}/secondary/${subCateg.slug}`} onClick={(e) => handleCategoryFilter(e, '/browse/' + allCateg.slug + '/' + subCateg.slug)}>{subCateg.name}</Link>
                                        </li>
                                      </ul>
                                      // </li>

                                    )
                                  }
                                })}
                              </ul>
                            )}
                          </li>

                        )
                      })}

                    </ul>
                    <div className="search-clm">
                      <div className="topsearch-c">
                        <div className="search-append">
                          <form action='/search' name="search_form" onSubmit={(e) => handleSearch(e)}>
                            <span className="clearable">
                              <input
                                className="span2 search-box"
                                name="keyword"
                                autoComplete="off"
                                type="text"
                                placeholder="Search"
                                onChange={(e) => handleSearchSugg(e)}
                                defaultValue={searchKey}
                              />
                              <div className="clearable__clear d-none" id='crossHeaderButton' onClick={(e) => handleClearSearch(e)}>
                                <svg
                                  fill="#c9c9cc9"
                                  className="close-icons"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 16 16"
                                  width="16px"
                                  height="16px"
                                >
                                  <path d="M 2.75 2.042969 L 2.042969 2.75 L 2.398438 3.101563 L 7.292969 8 L 2.042969 13.25 L 2.75 13.957031 L 8 8.707031 L 12.894531 13.605469 L 13.25 13.957031 L 13.957031 13.25 L 13.605469 12.894531 L 8.707031 8 L 13.957031 2.75 L 13.25 2.042969 L 8 7.292969 L 3.101563 2.398438 Z" />
                                </svg>
                              </div>

                              <svg
                                fill="#c9c9c9"
                                className="search-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 48 48"
                                width="24px"
                                height="24px"
                                onClick={(e) => handleSearch(e)}
                              >
                                <path d="M 20.5 6 C 12.509634 6 6 12.50964 6 20.5 C 6 28.49036 12.509634 35 20.5 35 C 23.956359 35 27.133709 33.779044 29.628906 31.75 L 39.439453 41.560547 A 1.50015 1.50015 0 1 0 41.560547 39.439453 L 31.75 29.628906 C 33.779044 27.133709 35 23.956357 35 20.5 C 35 12.50964 28.490366 6 20.5 6 z M 20.5 9 C 26.869047 9 32 14.130957 32 20.5 C 32 23.602612 30.776198 26.405717 28.791016 28.470703 A 1.50015 1.50015 0 0 0 28.470703 28.791016 C 26.405717 30.776199 23.602614 32 20.5 32 C 14.130953 32 9 26.869043 9 20.5 C 9 14.130957 14.130953 9 20.5 9 z" />
                              </svg>
                            </span>
                            <div className='dropdown'>
                              {searchResult?.products?.length > 0 ? searchResult?.products?.map((product, index) => {
                                return (
                                  <ul className=''>
                                    <li><Link to="#">{product.productName}</Link></li>
                                  </ul>
                                )
                              }) : ''
                              }
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>

          <SideMenu allCategories={allCategories} handleCategoryFilter={handleCategoryFilter} />

        </header>
        <div className="overlay-class"></div>
      </div>
    </>
  )
}

export default Header
